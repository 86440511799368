<template>
  <div id="subscription">
    <template v-if="secured_path">
      <iframe
        :src="secured_path"
        style="
          width: 100%;
          height: 740px;
          border: none;
          overflow: hidden !important;
        "
      ></iframe>
    </template>
    <template v-else>
      <template v-if="loading">
        <loading />
      </template>
      <template v-else>
        <div class="columns">
          <div class="column is-6 is-offset-3">
            <b-field label="Billing Type">
              <b-input
                name="billing-type"
                v-model="billingForPayment.subscriptionType"
                expanded
                disabled
              ></b-input>
            </b-field>
            <b-field label="Status">
              <b-input
                name="status"
                v-model="billingForPayment.status"
                expanded
                disabled
              ></b-input>
            </b-field>
            <b-field label="Month Factor">
              <b-input
                name="month-factor"
                v-model="billingForPayment.monthFactor"
                expanded
                disabled
              ></b-input>
            </b-field>
            <b-field label="Number of students">
              <b-input
                name="number-of-students"
                v-model="billingForPayment.numberOfStudents"
                expanded
                disabled
              ></b-input>
            </b-field>
            <b-field label="Subscription rate">
              <b-input
                name="subscription-rate"
                v-model="billingForPayment.subscriptionFee"
                expanded
                disabled
              ></b-input>
            </b-field>
            <b-field label="Total">
              <b-input
                name="total"
                v-model="billingForPayment.total"
                expanded
                disabled
              ></b-input>
            </b-field>
            <b-field label="Add 5% VAT">
              <b-input
                name="vat"
                v-model="billingForPayment.vat"
                expanded
                disabled
              ></b-input>
            </b-field>
            <b-field label="Amount Due">
              <b-input
                name="amount-due"
                v-model="billingForPayment.amountDue"
                expanded
                disabled
              ></b-input>
            </b-field>

            <b-field horizontal>
              <p class="control">
                <button
                  class="button is-success is-pulled-right"
                  @click="initialize()"
                >
                  Make Payment
                </button>
              </p>
            </b-field>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import StoreManager from './../../store/store_manager'
import { fetchSchool, fetchAppUrl, fetchToken } from '@/assets/js/app_info.js'

const axios = require('axios')

export default {
  name: 'subscription',
  data() {
    return {
      pageTitle: 'Subscription Payment',
      loading: true,
      path: null,
      token: null,
      secured_path: null,
      billingForPayment: {},
      billing_types: [
        { value: 'Termly', text: 'Termly' },
        { value: 'Half-Session', text: 'Half-Session' },
        { value: 'Sessional', text: 'Sessional' },
      ],
      reference: null,
      schoolId: null,
      school: null,
    }
  },
  apollo: {
    billingForPayment: {
      query: gql`
        query billingForPayment($schoolId: ID!) {
          billingForPayment(schoolId: $schoolId) {
            schoolId
            subscriptionType
            monthFactor
            status
            remainingDays
            statusMessage
            expired
            numberOfStudents
            subscriptionFee
            total
            vat
            amountDue
          }
        }
      `,
      variables() {
        return { schoolId: this.schoolId }
      },
      pollInterval: 1000,
    },
  },
  watch: {
    'billingForPayment.subscriptionType'(type) {
      this.loading = false
      if (type === 'Termly') {
        this.billingForPayment.monthFactor = 3
      } else if (type === 'Half-Session') {
        this.billingForPayment.monthFactor = 6
      } else if (type === 'Sessional') {
        this.billingForPayment.monthFactor = 12
      }
    },
    'billingForPayment.status'(value) {
      if (this.billingForPayment.expired) return

      if (value === 'Paid') {
        const { __typename, ...rest } = this.billingForPayment

        const billing = {
          id: rest.id,
          school_id: rest.schoolId,
          subscription_type: rest.subscriptionType,
          month_factor: rest.monthFactor,
          status: rest.status,
          remaining_days: rest.remainingDays,
          status_message: rest.statusMessage,
          expired: rest.expired,
        }

        StoreManager.save('billing', billing)
        this.$store.commit('setBilling', billing)

        this.$router.push(`/school/${this.schoolId}/dashboard`)
        this.$buefy.notification.open({
          duration: 5000,
          message: 'Payment verified.',
          position: 'is-top',
          type: 'is-success',
          hasIcon: true,
        })
      }
    },
  },
  methods: {
    initialize() {
      if (this.billingForPayment.numberOfStudents === 0) {
        this.$buefy.notification.open({
          duration: 5000,
          message: `Cannot perform this operation.<br>You must create students before you continue.`,
          position: 'is-top-right',
          type: 'is-info',
          hasIcon: true,
        })
        return
      } else {
        this.loading = true
        axios
          .get(`${this.path}/initialize_subscription_payment`, {
            params: {
              email: this.school.email,
              amount: parseFloat(this.billingForPayment.amountDue) * 100,
              token: this.token,
            },
          })
          .then((response) => {
            this.reference = response.data.data.reference

            if (location.hostname === 'localhost') {
              axios
                .get(`http://localhost:3000/verify_subscription_payment`, {
                  params: {
                    reference: this.reference,
                    payer: '4*:xpb%ks7pDW!Z!',
                  },
                })
                .catch((error) => {
                  this.$router.go(-1)
                  this.$buefy.notification.open({
                    duration: 5000,
                    message: error.message,
                    position: 'is-top-right',
                    type: 'is-danger',
                    hasIcon: true,
                  })
                  return
                })
            } else {
              this.secured_path = response.data.data.authorization_url
              this.loading = false
            }
          })
          .catch((error) => {
            // console.log(error.message);
          })
      }
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Subscriptions',
        route: `/school/${this.schoolId}/subscriptions`,
      },
    ])
    fetchSchool().then((school) => {
      this.school = school
    })
    fetchAppUrl().then((path) => {
      this.path = path
    })
    fetchToken().then((token) => {
      this.token = token
    })
  },
}
</script>
